.key::before {
  content: '\e938';
}
.magnificence::before {
  content: '\e90c';
}

.tick::before {
  content: '\e91a';
}

.tick-circle::before {
  content: '\e93f';
}

.dropdown-up::before {
  content: '\e908';
}

.dropdown-down::before {
  content: '\e907';
}

.pencil::before {
  content: '\e912';
}

.garbage::before {
  content: '\e925';
}

.close-cross::before {
  content: '\e905';
}

.plus::before {
  content: '\e913';
}

.delete-circle::before {
  content: '\e906';
}

.company::before {
  content: '\e92b';
}

.icon {
  color: $iconDefaultColor;
}

.disabled {
  color: $disabled;
}

.left-icon {
  position: absolute;
  left: 5px;
}

.right-icon {
  position: absolute;
  right: -5px;
}

/* This rule is required to let click event propagation working on svg button in Edge
  cf. https://stackoverflow.com/questions/35041903/ms-edge-cant-detect-delegated-events-for-use-svg-elements
*/
svg > use {
  pointer-events: none;
}
