.tooltipContainer {
  height: auto;
  z-index: 10002;
}
.tooltipArrow[data-placement*='bottom'] {
  left: 20px !important;
}

.tooltipContainer.dark {
  background-color: $tooltipDarkBackground;
  border: 1px solid $black;
  color: $tooltipDarkText;
}
.tooltipContainer.error {
  background-color: $white;
  border: 1px solid $textError;
  border-radius: 5px;
  color: $textError;
}

.tooltipContainer.info {
  background-color: $white;
  border: 1px solid $tooltipInfoBorder;
  border-radius: 5px;
  color: $tooltipInfoText;
  font-size: $defaultFontSize;
}

.tooltipArrow[data-placement='top'] {
  margin-bottom: 0;
  height: 0;
}

.tooltipContainer.info  .tooltipArrow[data-placement*='top']::before {
  border-color: $tooltipInfoBorder transparent transparent transparent;
  border-width: 5px;
  content: ' ';
}

.tooltipContainer.info .tooltipArrow[data-placement*='top']::after {
  border-color: $white transparent transparent transparent;
  border-width: 5px;
  content: ' ';
}

.tooltipContainer.error .tooltipArrow[data-placement*='bottom']::before {
  border-color: transparent transparent $textError transparent;
  border-width: 5px;
  content: ' ';
  margin-top: -5px;
  left: 1px;
}

.tooltipContainer.error .tooltipArrow[data-placement*='bottom']::after {
  border-color: transparent transparent $white transparent;
  border-width: 4px;
  content: ' ';
  left: 2px;
  top: -4px;
}

.tooltipContainer.error .tooltipArrow[data-placement*='top']::before {
  border-color: transparent;

  /* TODO */
}
.tooltipContainer.error .tooltipArrow[data-placement*='top']::after {
  border-color: transparent;

  /* TODO */
}
.tooltipContainer.error .tooltipArrow[data-placement*='left']::before {
  border-color: transparent;

  /* TODO */
}
.tooltipContainer.error .tooltipArrow[data-placement*='left']::after {
  border-color: transparent;

  /* TODO */
}
.tooltipContainer.error .tooltipArrow[data-placement*='right']::before {
  border-color: transparent $textError transparent transparent;
  border-width: 5px;
  content: ' ';
  margin-left: -4px;
}
.tooltipContainer.error .tooltipArrow[data-placement*='right']::after {
  border-color: transparent $white transparent transparent;
  border-width: 4px;
  content: ' ';
  left: -1px;
  top: 1px;
}
