.container {
  display: flex;
  flex-direction: column;
  align-content: center;
}
.login_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
}

.login_error {
  color: $textError;
  width: 270px;
  height: 30px;
  background-color: $white;
  padding: 4px;
  border: 1px solid $textError;
  border-radius: 6px;
  left: 115px;
  top: -10px;
}

.login {
  margin-top: -20px;
  margin-bottom: 40px;
}
.password {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.email {
  margin-bottom: 40px;
}

.login_info {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: $white;
}
