.Logo_container {
  top: 100px;
  left: 40px;
}

.logo {
  height: 77px;
}

.background {
  padding-left: 0;
  height: 100%;
  background: url('../../../images/direct_bg.jpg') no-repeat;
  background-size: cover;
}

.main_container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 380px 1fr;
}

.gridLogo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gridLogin {
  grid-column: 2;
  grid-row: 2;
}

.gridFooter {
  grid-column: 2;
  grid-row: 3;
  display: flex;
  align-items: flex-end;
}

.ie11Disclaimer {
  font-family: $brandFont;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $statusError;
  color: $white;
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
}

.ie11Link,
.ie11Link:active,
.ie11Link:visited,
.ie11Link:hover {
  color: $white;
  text-decoration: underline;
}
