@font-face {
  font-family: 'icomoon';
  src: resolve('../../src/fonts/icomoon.eot');
  src: resolve('../../src/fonts/icomoon.eot?#iefix') format('embedded-opentype'),
    resolve('../../src/fonts/icomoon.ttf') format('truetype'),
    resolve('../../src/fonts/icomoon.woff') format('woff'),
    resolve('../../src/fonts/icomoon.svg?#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

:global(.icomoon::before) {
  font-family: 'icomoon';
  font-style: normal;
}
