.primary {
  text-align: center;
  outline: none;
  border: 0;
  background-color: $primarybutton;
  color: $white;
  letter-spacing: 1px;
  font-size: 20px;
  line-height: 40px;
  border-radius: 5px;
  width: auto;
  height: 50px;
  cursor: pointer;
  box-shadow: 2px 2px 12px -3px $black;
}

.primary:hover {
  color: $primarybutton;
  background-color: $white;
  border: 2px solid $primarybutton;
}

.primary:active {
  background-color: $primarybuttonpressed;
  color: $white;
  border: 2px solid $primarybuttonpressed;
}

.primary-disabled,
.primary-disabled:hover {
  color: $white;
  border: solid 2px $disable;
  cursor: not-allowed;
  background-color: $disable;
  height: 50px;
  letter-spacing: 1px;
  font-size: 20px;
  line-height: 40px;
  border-radius: 5px;
  box-shadow: 2px 2px 12px -3px $black;
}

.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > span {
  width: 5px;
  height: 5px;
  margin-left: 2px;
  background-color: $white;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.primary:hover .spinner > span {
  background-color: $primarybutton;
}

.primary-disabled:hover .spinner > span {
  background-color: $white;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
