.container {
  text-align: left;
  position: relative;
}

.input {
  outline: 0;
  padding-left: 15px;
  display: inline-block;
  color: $borderInput; /* added */
}

.input-container {
  display: inline-block;
  position: relative;
}

.disabled .input {
  background-color: $disableUnselectedRadio;
  cursor: not-allowed;
}

.input-label {
  display: inline-block;
  margin-right: 5px;
  font-weight: normal;
}

.input-label-top {
  display: inline-block;
  margin-right: 5px;
  color: $white;
  letter-spacing: 1px;
  font-size: 16px;
  width: 25%;
}

.container-label-top {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
}

.input_error {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $textError;
  width: 270px;
  height: 25px;
  background-color: $white;
  padding: 5px;
  border: 1px solid $textError;
  border-radius: 6px;
  margin-left: 25px;
  margin-bottom: 3px;
}

.input_header {
  position: relative;
  display: flex;
  flex-direction: row;
}

.with-left-icon {
  padding-left: 20px;
}

.with-right-icon {
  padding-right: 20px;
}
